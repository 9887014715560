<template>
  <div class="history">
    <div class="head">
      <i class="el-icon-view"></i>
      <span>浏览记录</span>
    </div>

    <div class="nav-wrap">
      <ul>
        <li class="active">全部</li>
        <li>新零售</li>
        <li>工业4.0</li>
        <li>金融科技</li>
        <li>5G通信</li>
        <li>物联网应用</li>
        <li>智能制造</li>
      </ul>
    </div>

    <div class="content-panel">
      <div class="box-wraps">
        <div class="box" v-for="i in 5" :key="i">
          <div class="label">08-28</div>
          <div class="content">
            <div class="time">
              <span>时间：2021-09-03</span>
              <el-link style="margin-left: 4px" :underline="false"
                >删除</el-link
              >
            </div>

            <div class="items">
              <div class="item" v-for="i in 4" :key="i">
                <div class="img">
                  <img
                    src="http://dummyimage.com/180x115/7C83FD"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">商城网站千套模板一键建站</div>

                <span class="del-btn">
                  <i class="el-icon-close"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="pagination-wrap">
        <ori-pagination />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.history {
  .head {
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0px 25px;
    margin-bottom: 12px;

    i {
      font-size: 18px;
      padding-right: 4px;
    }
    span {
      font-weight: bold;
    }
  }

  .nav-wrap {
    background-color: #fff;
    margin-bottom: 15px;
    margin-top: 15px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 45px;
      line-height: 45px;
      li {
        cursor: pointer;
        display: inline-block;
        min-width: 140px;
        text-align: center;
        color: #666666;
        font-size: 14px;
        font-weight: bold;
        padding: 0px 20px;
        margin-right: 2px;

        &:hover,
        &:active,
        &.active {
          color: #fff;
          background-color: red;
        }
      }
    }
  }

  .content-panel {
    background: #ffffff;
    overflow: hidden;
    padding: 25px 25px 25px 25px;
    min-height: 650px;
    position: relative;

    .pagination-wrap {
      text-align: center;
      margin: 30px 0;
    }

    .box-wraps {
      position: relative;
      &::before {
        content: "";
        height: 100%;
        border-left: 1px solid #e6e6e6;
        position: absolute;
        left: 60px;
      }

      .box {
        overflow: hidden;
        padding-bottom: 20px;

        display: flex;
        align-items: flex-start;

        .label {
          width: 60px;
          text-align: center;
          font-size: 14px;
          position: relative;
          height: 19px;

          &::before {
            content: "";
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background: #fff;
            border: 2px solid #e62d31;
            position: absolute;
            left: 100%;
            top: 2px;
            margin-left: -7px;
            margin-top: 1px;
          }
        }

        .content {
          flex: 1 1 auto;
          padding-left: 20px;

          .time {
            font-size: 12px;
            color: #666;
            margin-bottom: 15px;
          }

          .items {
            .item {
              cursor: pointer;
              width: 180px;
              height: 160px;
              display: inline-block;
              overflow: hidden;
              margin-right: 15px;
              text-align: center;
              border: 1px solid #eee;
              position: relative;
              margin-bottom: 15px;

              &:hover,
              &:active {
                box-shadow: 0 0 10px #ddd;
                .img img {
                  transform: scale(1.2);
                }

                .title {
                  color: #e62d31;
                }

                .del-btn {
                  display: inline-block;
                }
              }

              .img {
                width: 100%;
                height: 115px;
                overflow: hidden;
                img {
                  width: 100%;
                  transition: all 0.8s ease-in;
                }
              }

              .title {
                font-size: 14px;
                height: 40px;
                line-height: 40px;
                font-weight: 700;
              }

              .del-btn {
                display: none;
                width: 18px;
                height: 18px;
                line-height: 18px;
                position: absolute;
                top: 5px;
                right: 5px;
                color: #fff;
                background-color: red;

                i {
                  vertical-align: baseline;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>